import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./updateModal.css";
import { useState} from "react";
import axios from 'axios';

function UpdateCompetitorModal(props){

const [show, setShow] = useState(true);

const handleClose = () => {setShow(false); window.location.reload(false);}
const refreshAfterUpdate = () =>{setShow(false); window.location.reload(false);}

const oldData = {
  WAID:props.waid,
  Nume:props.lastname,
  Prenume: props.firstname,
  Clasificare: props.classification,
  Proba: props.trial
};

const[updatedData,setUpdatedData]=useState(
    {
        WAID:props.waid,
        Nume:props.lastname,
        Prenume: props.firstname,
        Clasificare: props.classification,
        Proba: props.trial,
        TeamID : props.teamID
    }
  )

  function handleChange(event)
  {
    const id = event.target.id;
    const value = event.target.value;
    setUpdatedData(values => ({...values, [id]: value}))
  }

  function updateCompetitor()
  {
    axios.post('https://nodeppcbackend-production.up.railway.app/api/updateCompetitor', {
      Stage: props.stage,
      OldData: oldData,
      NewData: updatedData
    })
    .then((response) => {
      
      if(response.data === "Success!")
      {
        refreshAfterUpdate();
      }
      else
      {
        alert("Atentie! A aparut o eroare.")
      }

    })
  }

if(props.stage !== "ce2024_tm")
{
return(
    <div>
      <Modal dialogClassName="update-show" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
          <Modal.Body>
          <form>    
                        <input type="text" class="form-control rounded-pill shadow bottom mb-4" placeholder="WAID" aria-label="WAID" id="WAID" value={updatedData.WAID} onChange={handleChange}></input>
                        <input type="text" class="form-control rounded-pill shadow bottom mb-4" placeholder="Nume" aria-label="Nume" id="Nume" value={updatedData.Nume} required  onChange={handleChange}></input>
                        <input type="text" class="form-control rounded-pill shadow bottom mb-4" placeholder="Prenume" aria-label="Prenume" id="Prenume" value={updatedData.Prenume} required onChange={handleChange}></input>
                        <select class="form-select form-control rounded-pill shadow bottom mb-4" aria-label="Clasificare" id="Clasificare" onChange={handleChange}>
                            <option selected>{updatedData.Clasificare}</option>
                            <option value="HighMaster">High Master</option>
                            <option value="Master">Master</option>
                            <option value="Expert">Expert</option>
                            <option value="Sharpshooter">Sharpshooter</option>
                            <option value="Marksman">Marksman</option>
                            <option value="Unclassified">Unclassified</option>
                        </select>
                        <select class="form-select form-control rounded-pill shadow bottom mb-4" aria-label="Proba" id="Proba" onChange={handleChange}>
                            <option selected>{updatedData.Proba}</option>
                            <option value="R1500">R1500</option>
                            <option value="P1500">P1500</option>
                            <option value="OS1500">OS1500</option>
                            <option value="PCC">PCC1500</option>
                            <option value="DR">DR</option>
                            <option value="DP">DP</option>
                            <option value="Open Pistol">Open Pistol</option>
                            <option value="Open Revolver">Open Revolver</option>
                            <option value="SSA P5.5">SSA P5.5</option>
                            <option value="SSA P3.65">SSA P3.65</option>
                            <option value="SR4.25">SR4.25</option>
                            <option value="SR2.75">SR2.75</option>
                            <option value="SR2.75 5 shot">SR2.75 5 shot</option>
                            <option value="SSA P5.5 Special">SSA P5.5 Special</option>
                           
                        </select>
                    </form>
          </Modal.Body>
        <Modal.Footer>
          <Button variant="success mb-3 rounded-pill" onClick={updateCompetitor}>Modifica</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

}
else
{
  return(
    <div>
      <Modal dialogClassName="update-show" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
          <Modal.Body>
          <form>    
                        <input type="text" class="form-control rounded-pill shadow bottom mb-4" placeholder="WAID" aria-label="WAID" id="WAID" value={updatedData.WAID} onChange={handleChange}></input>
                        <input type="text" class="form-control rounded-pill shadow bottom mb-4" placeholder="Nume" aria-label="Nume" id="Nume" value={updatedData.Nume} required  onChange={handleChange}></input>
                        <input type="text" class="form-control rounded-pill shadow bottom mb-4" placeholder="Prenume" aria-label="Prenume" id="Prenume" value={updatedData.Prenume} required onChange={handleChange}></input>
                        <select class="form-select form-control rounded-pill shadow bottom mb-4" aria-label="Clasificare" id="Clasificare" onChange={handleChange}>
                            <option selected>{updatedData.Clasificare}</option>
                            <option value="HighMaster">High Master</option>
                            <option value="Master">Master</option>
                            <option value="Expert">Expert</option>
                            <option value="Sharpshooter">Sharpshooter</option>
                            <option value="Marksman">Marksman</option>
                            <option value="Unclassified">Unclassified</option>
                        </select>
                        <select class="form-select form-control rounded-pill shadow bottom mb-4" aria-label="Proba" id="Proba" onChange={handleChange}>
                            <option selected>{updatedData.Proba}</option>
                            <option value="Pistol">Pistol</option>
                            <option value="Revolver">Revolver</option>
                            <option value="Optic">Ootic</option>
                            <option value="PCC">PCC</option>             
                        </select>
                    </form>
          </Modal.Body>
        <Modal.Footer>
          <Button variant="success mb-3 rounded-pill" onClick={updateCompetitor}>Modifica</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );


}
}
export default UpdateCompetitorModal;