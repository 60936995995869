import {Link} from 'react-router-dom'
import "./SideNavbar.css"


function SideNavbar(props){

    return(
        <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style={{width:'280px',height:'100vh'}}>
            <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
            <i class="h1 bi bi-person-circle"></i>&nbsp;&nbsp;
            <span class="fs-4 mb-1">{props.username}</span></a>
        <hr></hr>
            <ul className="nav nav-pills flex-column text-center">
                <div class="dropdown">
                    <button class="btn btn-dark dropdown-toggle my-4" type="button" id="dropdownAddButton"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Adaugare concurent
                    </button>
                    <ul id="test" class="dropdown-menu" aria-labelledby="dropdownAddButton">
                        <li>
                            <a class="dropdown-item" href="#" data-bs-toggle="dropdown">Campionat National &raquo;</a>
                            <ul class="dropdown-menu dropdown-submenu">
                                <li><Link to = "/Adaugare/CN/Etapa1" class="dropdown-item">Etapa 1</Link></li>
                                <li><Link to = "/Adaugare/CN/Etapa2" class="dropdown-item">Etapa 2</Link></li>
                                <li><Link to = "/Adaugare/CN/Etapa3" class="dropdown-item">Etapa 3</Link></li>
                                <li><Link to = "/Adaugare/CN/Etapa4" class="dropdown-item">Etapa 4</Link></li>
                                <li><Link to = "/Adaugare/CN/Open" class="dropdown-item">Open</Link></li>
                                <li><Link to = "/Adaugare/CN/Etapa5" class="dropdown-item">Etapa 5</Link></li>
                                <li><Link to = "/Adaugare/CN/Etapa6" class="dropdown-item">Etapa 6</Link></li>
                                <li><Link to = "/Adaugare/CN/Etapa7" class="dropdown-item">Etapa 7</Link></li>
                                <li><Link to = "/Adaugare/CN/Etapa8" class="dropdown-item">Etapa 8</Link></li>
                            </ul>
                        </li>
                        <li><Link to = "/Adaugare/CE" class="dropdown-item">Campionat European</Link></li>
                    </ul>
                </div>
            </ul>
            <ul className="nav nav-pills flex-column text-center">
                <div class="dropdown">
                    <button class="btn btn-dark dropdown-toggle my-4" type="button" id="dropdownAddButton"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Overview
                    </button>
                    <ul id="test" class="dropdown-menu" aria-labelledby="dropdownAddButton">
                        <li>
                            <a class="dropdown-item" href="#" data-bs-toggle="dropdown">Campionat National &raquo;</a>
                            <ul class="dropdown-menu dropdown-submenu">
                                <li><Link to = "/Overview/CN/Etapa1" class="dropdown-item">Etapa 1</Link></li>
                                <li><Link to = "/Overview/CN/Etapa2" class="dropdown-item">Etapa 2</Link></li>
                                <li><Link to = "/Overview/CN/Etapa3" class="dropdown-item">Etapa 3</Link></li>
                                <li><Link to = "/Overview/CN/Etapa4" class="dropdown-item">Etapa 4</Link></li>
                                <li><Link to = "/Overview/CN/Open" class="dropdown-item">Open</Link></li>
                                <li><Link to = "/Overview/CN/Etapa5" class="dropdown-item">Etapa 5</Link></li>
                                <li><Link to = "/Overview/CN/Etapa6" class="dropdown-item">Etapa 6</Link></li>
                                <li><Link to = "/Overview/CN/Etapa7" class="dropdown-item">Etapa 7</Link></li>
                                <li><Link to = "/Overview/CN/Etapa8" class="dropdown-item">Etapa 8</Link></li>
                            </ul>
                        </li>
                        <li><Link to = "/Overview/CE" class="dropdown-item">Campionat European</Link></li>
                        <li><Link to = "/Overview/CETEAMS" class="dropdown-item">Campionat European Echipe</Link></li>
                    </ul>
                </div>
            </ul>
            <ul className="nav nav-pills flex-column text-center">
                <div class="dropdown">
                    <button class="btn btn-dark dropdown-toggle mb-4" type="button" id="dropdownAddButton"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Punctaj
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownAddButton">
                        <li>
                            <a class="dropdown-item" href="#" data-bs-toggle="dropdown">Campionat National &raquo;</a>
                            <ul class="dropdown-menu dropdown-submenu">
                                <li>
                                    <a className="dropdown-item">Etapa 1 &raquo;</a>
                                    <ul class="dropdown-menu dropdown-submenu">
                                        <li><Link to = "/Punctaj/CN/Etapa1/R1500" className='dropdown-item'>R1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa1/P1500" className='dropdown-item'>P1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa1/OS1500" className='dropdown-item'>OS1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa1/PCC1500" className='dropdown-item'>PCC1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa1/DR" className='dropdown-item'>DR</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa1/DP" className='dropdown-item'>DP</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa1/OpenRevolver" className='dropdown-item'>Open Revolver</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa1/OpenPistol" className='dropdown-item'>Open Pistol</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa1/SSAP5.5" className='dropdown-item'>SSA P5.5</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa1/SSAP3.65" className='dropdown-item'>SSA P3.65</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa1/SR2.755shot" className='dropdown-item'>SR2.75 5 shot</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa1/SR4.25" className='dropdown-item'>SR4.25</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa1/SR2.75" className='dropdown-item'>SR2.75</Link></li>
                                    
                                    </ul>
                                </li>
                                <li>
                                    <a className="dropdown-item">Etapa 2 &raquo;</a>
                                    <ul class="dropdown-menu dropdown-submenu">
                                        <li><Link to = "/Punctaj/CN/Etapa2/R1500" className='dropdown-item'>R1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa2/P1500" className='dropdown-item'>P1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa2/OS1500" className='dropdown-item'>OS1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa2/PCC1500" className='dropdown-item'>PCC1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa2/DR" className='dropdown-item'>DR</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa2/DP" className='dropdown-item'>DP</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa2/OpenRevolver" className='dropdown-item'>Open Revolver</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa2/OpenPistol" className='dropdown-item'>Open Pistol</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa2/SSAP5.5" className='dropdown-item'>SSA P5.5</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa2/SSAP3.65" className='dropdown-item'>SSA P3.65</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa2/SR2.755shot" className='dropdown-item'>SR2.75 5 shot</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa2/SR4.25" className='dropdown-item'>SR4.25</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa2/SR2.75" className='dropdown-item'>SR2.75</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="dropdown-item">Etapa 3 &raquo;</a>
                                    <ul class="dropdown-menu dropdown-submenu">
                                        <li><Link to = "/Punctaj/CN/Etapa3/R1500" className='dropdown-item'>R1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa3/P1500" className='dropdown-item'>P1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa3/OS1500" className='dropdown-item'>OS1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa3/PCC1500" className='dropdown-item'>PCC1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa3/DR" className='dropdown-item'>DR</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa3/DP" className='dropdown-item'>DP</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa3/OpenRevolver" className='dropdown-item'>Open Revolver</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa3/OpenPistol" className='dropdown-item'>Open Pistol</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa3/SSAP5.5" className='dropdown-item'>SSA P5.5</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa3/SSAP3.65" className='dropdown-item'>SSA P3.65</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa3/SR2.755shot" className='dropdown-item'>SR2.75 5 shot</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa3/SR4.25" className='dropdown-item'>SR4.25</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa3/SR2.75" className='dropdown-item'>SR2.75</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="dropdown-item">Etapa 4 &raquo;</a>
                                    <ul class="dropdown-menu dropdown-submenu">
                                        <li><Link to = "/Punctaj/CN/Etapa4/R1500" className='dropdown-item'>R1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa4/P1500" className='dropdown-item'>P1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa4/OS1500" className='dropdown-item'>OS1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa4/PCC1500" className='dropdown-item'>PCC1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa4/DR" className='dropdown-item'>DR</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa4/DP" className='dropdown-item'>DP</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa4/OpenRevolver" className='dropdown-item'>Open Revolver</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa4/OpenPistol" className='dropdown-item'>Open Pistol</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa4/SSA" className='dropdown-item'>SSA</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa4/SR4" className='dropdown-item'>SR4</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa4/ODP3.65" className='dropdown-item'>ODP3.65</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa4/ODR2.75" className='dropdown-item'>ODR2.75</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa4/ODR5" className='dropdown-item'>ODR5</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa4/SR2.75" className='dropdown-item'>SR2.75</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="dropdown-item">Open&raquo;</a>
                                    <ul class="dropdown-menu dropdown-submenu">
                                        <li><Link to = "/Punctaj/CN/Open/Pistol" className='dropdown-item'>Pistol</Link></li>
                                        <li><Link to = "/Punctaj/CN/Open/Revolver" className='dropdown-item'>Revolver</Link></li>
                                        <li><Link to = "/Punctaj/CN/Open/Optic" className='dropdown-item'>Optic</Link></li>
                                        <li><Link to = "/Punctaj/CN/Open/PCC" className='dropdown-item'>PCC</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="dropdown-item">Etapa 5 &raquo;</a>
                                    <ul class="dropdown-menu dropdown-submenu">
                                        <li><Link to = "/Punctaj/CN/Etapa5/R1500" className='dropdown-item'>R1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa5/P1500" className='dropdown-item'>P1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa5/OS1500" className='dropdown-item'>OS1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa5/PCC1500" className='dropdown-item'>PCC1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa5/DR" className='dropdown-item'>DR</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa5/DP" className='dropdown-item'>DP</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa5/OpenRevolver" className='dropdown-item'>Open Revolver</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa5/OpenPistol" className='dropdown-item'>Open Pistol</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa5/SSAP5.5" className='dropdown-item'>SSA P5.5</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa5/SSAP3.65" className='dropdown-item'>SSA P3.65</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa5/SR2.755shot" className='dropdown-item'>SR2.75 5 shot</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa5/SR4.25" className='dropdown-item'>SR4.25</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa5/SR2.75" className='dropdown-item'>SR2.75</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa5/SSAP5.5Special" className='dropdown-item'>SSA P5.5 Special</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="dropdown-item">Etapa 6 &raquo;</a>
                                    <ul class="dropdown-menu dropdown-submenu">
                                        <li><Link to = "/Punctaj/CN/Etapa6/R1500" className='dropdown-item'>R1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa6/P1500" className='dropdown-item'>P1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa6/OS1500" className='dropdown-item'>OS1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa6/PCC1500" className='dropdown-item'>PCC1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa6/DR" className='dropdown-item'>DR</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa6/DP" className='dropdown-item'>DP</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa6/OpenRevolver" className='dropdown-item'>Open Revolver</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa6/OpenPistol" className='dropdown-item'>Open Pistol</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa6/SSAP5.5" className='dropdown-item'>SSA P5.5</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa6/SSAP3.65" className='dropdown-item'>SSA P3.65</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa6/SR2.755shot" className='dropdown-item'>SR2.75 5 shot</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa6/SR4.25" className='dropdown-item'>SR4.25</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa6/SR2.75" className='dropdown-item'>SR2.75</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa6/SSAP5.5Special" className='dropdown-item'>SSA P5.5 Special</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="dropdown-item">Etapa 7 &raquo;</a>
                                    <ul class="dropdown-menu dropdown-submenu">
                                        <li><Link to = "/Punctaj/CN/Etapa7/R1500" className='dropdown-item'>R1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa7/P1500" className='dropdown-item'>P1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa7/OS1500" className='dropdown-item'>OS1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa7/PCC1500" className='dropdown-item'>PCC1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa7/DR" className='dropdown-item'>DR</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa7/DP" className='dropdown-item'>DP</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa7/OpenRevolver" className='dropdown-item'>Open Revolver</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa7/OpenPistol" className='dropdown-item'>Open Pistol</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa7/SSAP5.5" className='dropdown-item'>SSA P5.5</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa7/SSAP3.65" className='dropdown-item'>SSA P3.65</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa7/SR2.755shot" className='dropdown-item'>SR2.75 5 shot</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa7/SR4.25" className='dropdown-item'>SR4.25</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa7/SR2.75" className='dropdown-item'>SR2.75</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="dropdown-item">Etapa 8 &raquo;</a>
                                    <ul class="dropdown-menu dropdown-submenu">
                                        <li><Link to = "/Punctaj/CN/Etapa8/R1500" className='dropdown-item'>R1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa8/P1500" className='dropdown-item'>P1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa8/OS1500" className='dropdown-item'>OS1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa8/PCC1500" className='dropdown-item'>PCC1500</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa8/DR" className='dropdown-item'>DR</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa8/DP" className='dropdown-item'>DP</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa8/OpenRevolver" className='dropdown-item'>Open Revolver</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa8/OpenPistol" className='dropdown-item'>Open Pistol</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa8/SSAP5.5" className='dropdown-item'>SSA P5.5</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa8/SSAP3.65" className='dropdown-item'>SSA P3.65</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa8/SR2.755shot" className='dropdown-item'>SR2.75 5 shot</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa8/SR4.25" className='dropdown-item'>SR4.25</Link></li>
                                        <li><Link to = "/Punctaj/CN/Etapa8/SR2.75" className='dropdown-item'>SR2.75</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a className="dropdown-item">Campionat European &raquo;</a>
                            <ul class="dropdown-menu dropdown-submenu">
                                        <li><Link to = "/Punctaj/CE/R1500" className='dropdown-item'>R1500</Link></li>
                                        <li><Link to = "/Punctaj/CE/P1500" className='dropdown-item'>P1500</Link></li>
                                        <li><Link to = "/Punctaj/CE/OS1500" className='dropdown-item'>OS1500</Link></li>
                                        <li><Link to = "/Punctaj/CE/PCC1500" className='dropdown-item'>PCC1500</Link></li>
                                        <li><Link to = "/Punctaj/CE/DR" className='dropdown-item'>DR</Link></li>
                                        <li><Link to = "/Punctaj/CE/DP" className='dropdown-item'>DP</Link></li>
                                        <li><Link to = "/Punctaj/CE/OpenRevolver" className='dropdown-item'>Open Revolver</Link></li>
                                        <li><Link to = "/Punctaj/CE/OpenPistol" className='dropdown-item'>Open Pistol</Link></li>
                                        <li><Link to = "/Punctaj/CE/SSAP5.5" className='dropdown-item'>SSA P5.5</Link></li>
                                        <li><Link to = "/Punctaj/CE/SSAP3.65" className='dropdown-item'>SSA P3.65</Link></li>
                                        <li><Link to = "/Punctaj/CE/SR2.755shot" className='dropdown-item'>SR2.75 5 shot</Link></li>
                                        <li><Link to = "/Punctaj/CE/SR4.25" className='dropdown-item'>SR4.25</Link></li>
                                        <li><Link to = "/Punctaj/CE/SR2.75" className='dropdown-item'>SR2.75</Link></li>
                                        <li><Link to = "/Punctaj/CE/SSAP5.5Special" className='dropdown-item'>SSA P5.5 Special</Link></li>
                            </ul>
                        </li>
                        <li>
                            <a className="dropdown-item">Campionat European TEAMS&raquo;</a>
                            <ul class="dropdown-menu dropdown-submenu">
                                        <li><Link to = "/Punctaj/CETEAMS/Pistol" className='dropdown-item'>Pistol</Link></li>
                                        <li><Link to = "/Punctaj/CETEAMS/Revolver" className='dropdown-item'>Revolver</Link></li>
                                        <li><Link to = "/Punctaj/CETEAMS/Optic" className='dropdown-item'>Optic</Link></li>
                                        <li><Link to = "/Punctaj/CETEAMS/PCC" className='dropdown-item'>PCC</Link></li>
                                       
                            </ul>
                        </li>
                    </ul>
                </div>
            </ul>
            <ul className="nav nav-pills flex-column mb-auto text-center">
                <div class="dropdown">
                    <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownPDFButton"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        PDF
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownPDFButton">
                        <li>
                            <a class="dropdown-item" href="#" data-bs-toggle="dropdown">Campionat National &raquo;</a>
                            <ul class="dropdown-menu dropdown-submenu">
                                <li><Link to = "/PDF/CN/Etapa1" class="dropdown-item">Etapa 1</Link></li>
                                <li><Link to = "/PDF/CN/Etapa2" class="dropdown-item">Etapa 2</Link></li>
                                <li><Link to = "/PDF/CN/Etapa3" class="dropdown-item">Etapa 3</Link></li>
                                <li><Link to = "/PDF/CN/Etapa4" class="dropdown-item">Etapa 4</Link></li>
                                <li><Link to = "/PDF/CN/Open" class="dropdown-item">Open</Link></li>
                                <li><Link to = "/PDF/CN/Etapa5" class="dropdown-item">Etapa 5</Link></li>
                                <li><Link to = "/PDF/CN/Etapa6" class="dropdown-item">Etapa 6</Link></li>
                                <li><Link to = "/PDF/CN/Etapa7" class="dropdown-item">Etapa 7</Link></li>
                                <li><Link to = "/PDF/CN/Etapa8" class="dropdown-item">Etapa 8</Link></li>
                            </ul>
                        </li>
                        <li><Link to = "/PDF/CE" class="dropdown-item">Campionat European</Link></li>
                        <li><Link to = "/PDF/CETEAMS" class="dropdown-item">Campionat European Echipe</Link></li>
                    </ul>
                </div>
            </ul>
        <hr></hr>
        <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
            <span class="fs-4 mb-1">PPC Scoring</span></a>
        </div>
    );
}

export default SideNavbar;