import ScoringModal from "./ScoringModal";
import { useState, useEffect } from "react";
import axios from "axios";
import "./Competitors.css"

function Competitors(props){

    const Stages = {
        "etapa1": "Etapa I",
        "etapa2": "Etapa II",
        "etapa3": "Etapa III",
        "etapa4": "Etapa IV",
        "etapa5": "Etapa V",
        "etapa6": "Etapa VI",
        "etapa7": "Etapa VII",
        "etapa8": "Etapa VIII",
        "ce2024": "Campionat European 2024",
        "ce2024_tm" : "Campionat European 2024 Echipe",
        "open_tm": "Romania OPEN"
        
      }
    
    const [show, setShow] = useState(false);
    const [competitorData, setCompetitorData] = useState();
    const [competitorWAID, setCompetitorWAID] = useState('');
    const [competitorLN, setCompetitorLN] = useState('');
    const [competitorFN, setCompetitorFN] = useState('');
    const [teamID, setTeamID] = useState('');
    const [all, setAll] = useState(true);
  
    const prev_stage = window.localStorage.getItem("prev_stage");
    const prev_trial = window.localStorage.getItem("prev_trial");
    
    if((prev_trial !== props.trial) || (prev_stage !== props.stage)){
        window.localStorage.setItem("prev_trial", props.trial)
        window.localStorage.setItem("prev_stage", props.stage)
        window.location.reload(false);
    }

    function handleClick(event){
        setShow(true);
        var data = event.target.id;
        data = data.split(' ');       
        if(data[0] === ""){
            setCompetitorWAID("x")
            setCompetitorLN(data[2]);
            setCompetitorFN(data[3]);
           
        }
        else{
            setCompetitorWAID(data[0])
            setCompetitorLN(data[1]);
            setCompetitorFN(data[2]);
            
        }
        
    }

    function handleTeamClick(event){
        setShow(true);
        var data = event.target.id;
        data = data.split(' '); 
        if(data[0].length === 0){
            
            setCompetitorWAID("x")
            setCompetitorLN(data[2]);
            setCompetitorFN(data[3]);
            setTeamID(data[5]);
           
        }
        else{
            setCompetitorWAID(data[0])
            setCompetitorLN(data[1]);
            setCompetitorFN(data[2]);
            setTeamID(data[4]);
            
        }
        
    }

    useEffect(() => {
        axios.get('https://nodeppcbackend-production.up.railway.app/api/getCompetitor', {
            params : {
                Stage:props.stage, 
                Trial:props.trial
            }})
        .then((response) => {
            
           console.log(response.data);
           setCompetitorData(response.data)
           
        })
     }, [])


     const[searchName,setCompetitorName]=useState(
        {
            Name:""
        
        }
      )

      function handleChange(event)
      {
        const id = event.target.id;
        const value = event.target.value;
        setCompetitorName(values => ({...values, [id]: value}))
    
      }

      useEffect(() =>
    {
        var name = searchName.Name;      
        const showAll = name.length === 0 ? true:false;
        setAll(showAll);
        if(showAll === true)
        {
            axios.get('https://nodeppcbackend-production.up.railway.app/api/getCompetitor', {
            params : {
                Stage:props.stage, 
                Trial:props.trial
            }})
        .then((response) => {
            
           console.log(response.data);
           setCompetitorData(response.data)
           
        })
        }
        else 
        {
            axios.get('https://nodeppcbackend-production.up.railway.app/api/searchCompetitor', {
            params : {
                Stage:props.stage,
                Name: searchName.Name,
                Trial: props.trial
            }})
            .then((response) => {
            
                setCompetitorData(response.data)
           
            })
        }
        
    },[searchName])

    /* Here will be decided if is team or individual using the stage props */

    if((props.stage === "ce2024_tm") && (all === true))
    {
        const competitors = competitorData?.map((data,index) =>{
            
            if(data.WAID === "NA")
            {
                data.WAID = " "
            }
            if((index>0) && (index % 2 == 0)){
                return(
                <>
                <tr><td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                </tr>
                <tr>
                    <td><i className="bi bi-journal-text btn" id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial + " " + data.teamID } onClick={handleTeamClick}></i></td>
                    <td>{data.teamID}</td>
                    <td>{data.WAID}</td>
                    <td>{data.LastName}</td>
                    <td>{data.FirstName}</td>
                    <td>{data.REG}</td>
                    <td>{data.Trial}</td>
                    <td>{data.Classification}</td>
                    <td>{data.tm_TotalX}</td>
                    <td>{data.tm_Points}</td>
                    <td>{data.teams_TotalX}</td>
                    <td>{data.teams_Points}</td>

                </tr>
                </>
                );
            }
            else
            {
                return(
                    <tr>
                        <td><i className="bi bi-journal-text btn" id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial + " " + data.teamID } onClick={handleTeamClick}></i></td>
                        <td>{data.teamID}</td>
                        <td>{data.WAID}</td>
                        <td>{data.LastName}</td>
                        <td>{data.FirstName}</td>
                        <td>{data.REG}</td>
                        <td>{data.Trial}</td>
                        <td>{data.Classification}</td>
                        <td>{data.tm_TotalX}</td>
                        <td>{data.tm_Points}</td>
                        <td>{data.teams_TotalX}</td>
                        <td>{data.teams_Points}</td>
    
                    </tr>
                    );
            }
        })
      
        return(
        <div>
            <br></br>
            <div className="container-fluid text-center" style={{width:'20vw', marginLeft:"-6.5vw"}}>
                <div className="card shadow-sm">
                    <h2>Punctaj {Stages[props.stage]} {props.trial}</h2>
                    <br></br>
                </div>
                </div>
               
        <div>
             
            {show && <ScoringModal stage ={props.stage} trial = {props.trial} shots={props.shots} waid={competitorWAID} lastname={competitorLN} firstname={competitorFN} teamID={teamID}/>}
            <form >    
                    <input type="text" class="form-control rounded-pill" placeholder="Nume" aria-label="Name" id="Name" onChange={handleChange} style={{position:"absolute", marginTop:"-6vh", marginLeft:"-18vw", width:"250px"}}></input>
            </form> 
            <div className="scrollit" style={{marginTop:"15vh", marginLeft:"-18vw", width:"900px"}}>
            <div class="table-responsive-lg">
                <table className="table table-borderless text-center">
                <thead>
                    <tr className="table-dark">
                        <th scope="col">Actiuni</th>
                        <th scope="col">TEAM ID</th>
                        <th scope="col">WA1500</th>
                        <th scope="col">Nume</th>
                        <th scope="col">Prenume</th>
                        <th scope="col">REG</th>
                        <th scope="col">Proba</th>
                        <th scope="col">Clasificare</th>
                        <th scope="col">Total X</th>
                        <th scope="col">Punctaj</th>
                        <th scope="col">Total X TEAM</th>
                        <th scope="col">Punctaj TEAM</th>
                        
                    </tr>
                </thead>
                <tbody className="table-secondary">
                    {competitors}
                </tbody>
            </table>
            </div>
            </div>
        </div>
        </div>
        );
    }
    else if((props.stage === "ce2024_tm") && (all === false))
        {
            const competitors = competitorData?.map((data,index) =>{
                
                if(data.WAID === "NA")
                {
                    data.WAID = " "
                }
                if((index>0) && (index % 2 == 0)){
                    return(
                    <>
                    <tr><td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                    </tr>
                    <tr>
                        <td><i className="bi bi-journal-text btn" id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial + " " + data.teamID } onClick={handleTeamClick}></i></td>
                        <td>{data.teamID}</td>
                        <td>{data.WAID}</td>
                        <td>{data.LastName}</td>
                        <td>{data.FirstName}</td>
                        <td>{data.REG}</td>
                        <td>{data.Trial}</td>
                        <td>{data.Classification}</td>
                        <td>{data.TotalX}</td>
                        <td>{data.Points}</td>
                        <td>-</td>
                        <td>-</td>
    
                    </tr>
                    </>
                    );
                }
                else
                {
                    return(
                        <tr>
                            <td><i className="bi bi-journal-text btn" id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial + " " + data.teamID } onClick={handleTeamClick}></i></td>
                            <td>{data.teamID}</td>
                            <td>{data.WAID}</td>
                            <td>{data.LastName}</td>
                            <td>{data.FirstName}</td>
                            <td>{data.REG}</td>
                            <td>{data.Trial}</td>
                            <td>{data.Classification}</td>
                            <td>{data.TotalX}</td>
                            <td>{data.Points}</td>
                            <td>-</td>
                            <td>-</td>
        
                        </tr>
                        );
                }
            })
          
            return(
            <div>
                <br></br>
                <div className="container-fluid text-center" style={{width:'20vw', marginLeft:"-6.5vw"}}>
                    <div className="card shadow-sm">
                        <h2>Punctaj {Stages[props.stage]} {props.trial}</h2>
                        <br></br>
                    </div>
                    </div>
                   
            <div>
                 
                {show && <ScoringModal stage ={props.stage} trial = {props.trial} shots={props.shots} waid={competitorWAID} lastname={competitorLN} firstname={competitorFN} teamID={teamID}/>}
                <form >    
                        <input type="text" class="form-control rounded-pill" placeholder="Nume" aria-label="Name" id="Name" onChange={handleChange} style={{position:"absolute", marginTop:"-6vh", marginLeft:"-18vw", width:"250px"}}></input>
                </form> 
                <div className="scrollit" style={{marginTop:"15vh", marginLeft:"-18vw", width:"900px"}}>
                <div class="table-responsive-lg">
                    <table className="table table-borderless text-center">
                    <thead>
                        <tr className="table-dark">
                            <th scope="col">Actiuni</th>
                            <th scope="col">TEAM ID</th>
                            <th scope="col">WA1500</th>
                            <th scope="col">Nume</th>
                            <th scope="col">Prenume</th>
                            <th scope="col">REG</th>
                            <th scope="col">Proba</th>
                            <th scope="col">Clasificare</th>
                            <th scope="col">Total X</th>
                            <th scope="col">Punctaj</th>
                            <th scope="col">Total X TEAM</th>
                            <th scope="col">Punctaj TEAM</th>
                            
                        </tr>
                    </thead>
                    <tbody className="table-secondary">
                        {competitors}
                    </tbody>
                </table>
                </div>
                </div>
            </div>
            </div>
            );
        }  
    else{     
    const competitors = competitorData?.map((data,index) =>{
        if(data.WAID === "NA")
        {
            data.WAID = " "
        }
        return(
        <tr>
            <td><i className="bi bi-journal-text btn" id = {data.WAID + " " + data.LastName + " " + data.FirstName + " " + data.Trial } onClick={handleClick}></i></td>
            <td>{data.WAID}</td>
            <td>{data.LastName}</td>
            <td>{data.FirstName}</td>
            <td>{data.REG}</td>
            <td>{data.Trial}</td>
            <td>{data.Classification}</td>
            <td>{data.TotalX}</td>
            <td>{data.Points}</td>
            
        </tr>
        );
    })
  
    return(
    <div>
        <br></br>
        <div className="container-fluid text-center" style={{width:'20vw', marginLeft:"-6.5vw"}}>
            <div className="card shadow-sm">
                <h2>Punctaj {Stages[props.stage]} {props.trial}</h2>
                <br></br>
            </div>
            </div>
           
    <div>
         
        {show && <ScoringModal stage ={props.stage} trial = {props.trial} shots={props.shots} waid={competitorWAID} lastname={competitorLN} firstname={competitorFN}/>}
        <form >    
                <input type="text" class="form-control rounded-pill" placeholder="Nume" aria-label="Name" id="Name" onChange={handleChange} style={{position:"absolute", marginTop:"-6vh", marginLeft:"-18vw", width:"250px"}}></input>
        </form> 
        <div className="scrollit" style={{marginTop:"15vh", marginLeft:"-18vw", width:"900px"}}>
        <div class="table-responsive-lg">
            <table className="table table-borderless text-center">
            <thead>
                <tr className="table-dark">
                    <th scope="col">Actiuni</th>
                    <th scope="col">WA1500</th>
                    <th scope="col">Nume</th>
                    <th scope="col">Prenume</th>
                    <th scope="col">REG</th>
                    <th scope="col">Proba</th>
                    <th scope="col">Clasificare</th>
                    <th scope="col">Total X</th>
                    <th scope="col">Punctaj</th>
                    
                </tr>
            </thead>
            <tbody className="table-secondary">
                {competitors}
            </tbody>
        </table>
        </div>
        </div>
    </div>
    </div>
    );
}

    
}

export default Competitors;